let searchIcon;
let searchPage = document.querySelector('body.search');
if(window.innerWidth < 1024) {
    searchIcon = document.querySelector('header .search-wrapper.mobile button.search-btn');
}
else {
    searchIcon = document.querySelector('header .search-wrapper.desktop button.search-btn');
}

if (searchIcon) {
    let wrapper = searchIcon.parentElement;
    let search = wrapper.querySelector('form');
    let searchInput = document.querySelector('input');
    searchIcon.addEventListener('click', function (e) {
        e.preventDefault();
        if(search.classList.contains('expand')) {
            search.classList.remove('expand');
        }
        else {
            search.classList.add('expand');
            searchInput.focus();
        }
    });
}

if (searchPage) {
    let pageSearchInput = searchPage.querySelector('#search-page #search-page-input');
    let inputValue = searchPage.querySelector('#content-section #search-page #search-page-input').value;
    pageSearchInput.focus();
    pageSearchInput.value = '';
    pageSearchInput.value = inputValue;
}