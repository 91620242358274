const breakpoint = 1024;
const header = document.querySelector('header');

if(header) {
    const wrapperContent = header.querySelector('.wrapper').innerHTML;
    let headerHeight = header.offsetHeight;
    let lastScrollTop = 0;

    // Menu on scroll
    $(window).scroll(function (e) {
        let scrollTop = $(this).scrollTop();
        if ($(window).width() >= breakpoint && scrollTop <= 2) {
            header.classList.remove('sticky', 'show', 'animate');
        } 
        else if (scrollTop === 0) {
            header.classList.remove('sticky', 'show', 'animate');
        } 
        else if (scrollTop > headerHeight) {
            if (scrollTop < lastScrollTop) { // Scrolling up
                header.classList.add('sticky', 'animate', 'show')
            } else {
                header.classList.add('sticky');
                header.classList.remove('show');
            }
        }
        lastScrollTop = scrollTop;
    });

    setMobileNav();

    window.addEventListener('resize', function() {
        header.querySelector('.wrapper').innerHTML = wrapperContent;
        setMobileNav();
    });

    function openMenu(menu) {
        document.querySelector("header .menu-content").classList.add("show");
        document.querySelector("header .menu-content").classList.add("anim");
        document.querySelector(".menu-button").classList.add("menu-close");
        document.querySelector(".investors-button").classList.add("hide");
        document.querySelector(".search-wrapper.desktop").classList.add("hide");
        setTimeout(function() {
            header.classList.add('show-menu');
        }, 300);
        header.querySelector(".wrapper").classList.add('anim');
        document.querySelector("body").classList.add('no-scroll');
        document.querySelector(".close-button").classList.add('show');
        document.querySelector("." + menu + '-menu').classList.add('show');
        // Hides dropdown when menu is hidden
        document.querySelectorAll(".menu-item-has-children >a").forEach(function (ele) {
            ele.classList.remove("show-ul");
        });
    }

    function closeMenu() {
        document.querySelector("header .menu-content").classList.remove("anim");
        setTimeout(function() {
            document.querySelector("header .menu-content").classList.remove("show");
        }, 300);
        header.querySelector(".wrapper").classList.remove('anim');
        document.querySelector(".menu-button").classList.remove("menu-close");
        document.querySelector(".investors-button").classList.remove("hide");
        document.querySelector(".search-wrapper.desktop").classList.remove("hide");
        header.classList.remove('show-menu');
        document.querySelector("body").classList.remove('no-scroll');
        document.querySelector(".close-button").classList.remove('show');
        document.querySelector("ul.show").classList.remove('show');
    }

    function setMobileNav() {

        document.querySelector(".menu-button").onclick = function toggleMenu() {
     /*        if (document.querySelector("header .menu-content").matches('.show')) {
                closeMenu();
            }
            else { */
                openMenu('main');
           /*  } */
        };

        document.querySelector(".investors-button").onclick = function toggleMenu() {
/*             if (document.querySelector("header .menu-content").matches('.show')) {
                closeMenu();
            }
            else { */
                openMenu('investors');
          /*   } */
        }

        document.querySelector(".close-button").onclick = function toggleMenu() {
            closeMenu();
        }

        //dropdown
        /**
         * check if parent has child with classname
         * on click add or remove class
        */
        document.querySelectorAll(".menu-item-has-children >a").forEach(function (ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    window.location = e.target.href;
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                }
            });
        });
        document.querySelectorAll(".menu-item-has-children >svg").forEach(function(ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    ele.parentNode.classList.remove("show-ul");
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                }
            });
        });
    }

    function setDesktopNav() {
        const links = document.querySelectorAll(".menu-item-has-children >a");
        const descendants = document.querySelectorAll(" .menu-item-has-children > ul > li > a");

        descendants.forEach(descendant => {
            descendant.addEventListener('focus', (event) => {
                const grandParent = descendant.parentElement.parentElement.parentElement;
                grandParent.classList.add("show-ul");
            });

            descendant.addEventListener('blur', (event) => {
                const grandParent = descendant.parentElement.parentElement.parentElement;
                grandParent.classList.remove("show-ul");
            });
        });

    links.forEach(link => {
            link.addEventListener('focus', (event) => {
                link.parentNode.classList.add("show-ul");
            });
        
            link.addEventListener('blur', (event) => {
                link.parentNode.classList.remove("show-ul");
            });
        });
    }
}